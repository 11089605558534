import React, { useState } from "react"
import { CSSTransition } from "react-transition-group"
import FeatureSectionDE from "@components/FeatureSectionDE"
import alertTransition from "../../css/transitions/alert.module.scss"

function Home() {
  const [mobileNavOpen, setMobileNavOpen] = useState(false)
  const [ctaClicked, setCtaClicked] = useState(false)

  const scrollToId = (id: string): void => {
    document.getElementById(id).scrollIntoView({
      behavior: "smooth",
    })
    setTimeout(() => {
      document.getElementById(id).focus()
    }, 800)
    setCtaClicked(true)
  }

  return (
    <>
      <Hero
        mobileNavOpen={mobileNavOpen}
        setMobileNavOpen={setMobileNavOpen}
        scrollToId={scrollToId}
      />
      <FeatureSectionDE />
      <Newsletter ctaClicked={ctaClicked} />
      <Footer />
    </>
  )
}

function Hero({ mobileNavOpen, setMobileNavOpen, scrollToId }) {
  return (
    <section className="relative bg-white overflow-hidden">
      <div className="hidden sm:block sm:absolute sm:inset-y-0 sm:h-full sm:w-full">
        <div className="relative h-full max-w-screen-xl mx-auto">
          <img
            className="absolute right-full transform translate-y-1/4 translate-x-1/4 lg:translate-x-1/2 opacity-25"
            width="300"
            height="784"
            src="/left-circles.svg"
            alt="left circles deco"
          />
          <img
            className="absolute left-full transform -translate-y-3/4 -translate-x-1/4 md:-translate-y-1/6 lg:-translate-x-1/2 opacity-25"
            width="300"
            height="784"
            src="/right-circles.svg"
            alt="right circles deco"
          />
        </div>
      </div>

      <div className="relative pt-6 pb-12 sm:pb-16 md:pb-20 lg:pb-16 xl:pb-20">
        <div className="max-w-screen-xl mx-auto px-4 sm:px-6">
          <nav className="relative flex items-center justify-between sm:h-10 md:justify-center">
            <div className="flex items-center flex-1 md:absolute md:inset-y-0 md:left-0">
              <div className="flex items-center justify-between w-full md:w-auto">
                <a href="/">
                  <img className="h-8 w-auto sm:h-10" src="/cuddy.svg" alt="" />
                </a>
                <div className="-mr-2 flex items-center md:hidden">
                  <button
                    onClick={(): void => setMobileNavOpen(true)}
                    type="button"
                    className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 focus:text-gray-500 transition duration-150 ease-in-out"
                  >
                    <svg
                      className="h-8 w-8"
                      stroke="currentColor"
                      fill="none"
                      viewBox="0 0 24 24"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M4 6h16M4 12h16M4 18h16"
                      />
                    </svg>
                  </button>
                </div>
              </div>
            </div>
            <div className="hidden md:block">
              <a
                href="#features"
                className="font-semibold text-gray-500 hover:text-gray-900 focus:outline-none focus:text-gray-900 transition duration-150 ease-in-out"
              >
                Features
              </a>
              <a
                href="#newsletter"
                className="ml-10 font-semibold text-gray-500 hover:text-gray-900 focus:outline-none focus:text-gray-900 transition duration-150 ease-in-out"
              >
                Formular ausfüllen
              </a>
            </div>
            <div className="hidden md:absolute md:flex md:items-center md:justify-end md:inset-y-0 md:right-0">
              <span className="inline-flex rounded-md shadow">
                <a
                  href="#newsletter"
                  className="inline-flex items-center px-4 py-2 border border-transparent text-base leading-6 font-semibold rounded-md text-blue-600 bg-white hover:text-blue-500 focus:outline-none focus:shadow-outline-blue active:bg-gray-50 active:text-blue-700 transition duration-150 ease-in-out"
                >
                  Lernpartner finden
                </a>
              </span>
            </div>
          </nav>
        </div>
        <CSSTransition
          in={mobileNavOpen}
          timeout={300}
          classNames={alertTransition}
          unmountOnExit
        >
          <MobileNav
            setMobileNavOpen={setMobileNavOpen}
            scrollToId={scrollToId}
          />
        </CSSTransition>

        <Content scrollToId={scrollToId} />
      </div>
    </section>
  )
}

function Content({ scrollToId }) {
  return (
    <div className="mt-10 mx-auto max-w-screen-xl px-4 sm:mt-12 sm:px-6 md:mt-16 lg:mt-20 xl:mt-28">
      <div className="text-center">
        <h2 className="text-3xl tracking-tight leading-10 font-semibold text-gray-800 sm:text-3xl sm:leading-none md:text-6xl">
          Gemeinsam lernen trotz Corona
        </h2>
        <p className="mt-3 max-w-md mx-auto text-lg text-gray-500 sm:text-lg md:mt-5 md:text-xl md:max-w-3xl">
          Finde Leute die den gleichen Studien-Kurs belegen und lerne gemeinsam
          - online und trotz Quarantäne!
        </p>
        <div className="mt-5 max-w-md mx-auto sm:flex sm:justify-center md:mt-8">
          <div className="rounded-md shadow">
            <a
              href="#newsletter"
              className="w-full flex items-center justify-center px-8 py-3 border border-transparent text-base leading-6 font-medium rounded-md text-white bg-blue-600 hover:bg-blue-500 focus:outline-none focus:shadow-outline-blue transition duration-150 ease-in-out md:py-4 md:text-lg md:px-10"
            >
              Lernpartner finden
              <span role="img" aria-label="Smart" className="ml-1">
                🤓
              </span>
            </a>
          </div>
        </div>
      </div>
    </div>
  )
}

function Footer() {
  return (
    <section className="bg-lightgray">
      <div className="max-w-screen-xl mx-auto py-12 px-4 sm:px-6 md:flex md:items-center md:justify-between lg:px-8">
        <div className="flex justify-center md:order-2 pointer-events-none">
          <a href="/" className="text-gray-400 hover:text-gray-500">
            <span className="sr-only">Facebook</span>
            <svg className="h-6 w-6" fill="currentColor" viewBox="0 0 24 24">
              <path
                fillRule="evenodd"
                d="M22 12c0-5.523-4.477-10-10-10S2 6.477 2 12c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V12h2.54V9.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V12h2.773l-.443 2.89h-2.33v6.988C18.343 21.128 22 16.991 22 12z"
                clipRule="evenodd"
              />
            </svg>
          </a>
          <a href="/" className="ml-6 text-gray-400 hover:text-gray-500">
            <span className="sr-only">Instagram</span>
            <svg className="h-6 w-6" fill="currentColor" viewBox="0 0 24 24">
              <path
                fillRule="evenodd"
                d="M12.315 2c2.43 0 2.784.013 3.808.06 1.064.049 1.791.218 2.427.465a4.902 4.902 0 011.772 1.153 4.902 4.902 0 011.153 1.772c.247.636.416 1.363.465 2.427.048 1.067.06 1.407.06 4.123v.08c0 2.643-.012 2.987-.06 4.043-.049 1.064-.218 1.791-.465 2.427a4.902 4.902 0 01-1.153 1.772 4.902 4.902 0 01-1.772 1.153c-.636.247-1.363.416-2.427.465-1.067.048-1.407.06-4.123.06h-.08c-2.643 0-2.987-.012-4.043-.06-1.064-.049-1.791-.218-2.427-.465a4.902 4.902 0 01-1.772-1.153 4.902 4.902 0 01-1.153-1.772c-.247-.636-.416-1.363-.465-2.427-.047-1.024-.06-1.379-.06-3.808v-.63c0-2.43.013-2.784.06-3.808.049-1.064.218-1.791.465-2.427a4.902 4.902 0 011.153-1.772A4.902 4.902 0 015.45 2.525c.636-.247 1.363-.416 2.427-.465C8.901 2.013 9.256 2 11.685 2h.63zm-.081 1.802h-.468c-2.456 0-2.784.011-3.807.058-.975.045-1.504.207-1.857.344-.467.182-.8.398-1.15.748-.35.35-.566.683-.748 1.15-.137.353-.3.882-.344 1.857-.047 1.023-.058 1.351-.058 3.807v.468c0 2.456.011 2.784.058 3.807.045.975.207 1.504.344 1.857.182.466.399.8.748 1.15.35.35.683.566 1.15.748.353.137.882.3 1.857.344 1.054.048 1.37.058 4.041.058h.08c2.597 0 2.917-.01 3.96-.058.976-.045 1.505-.207 1.858-.344.466-.182.8-.398 1.15-.748.35-.35.566-.683.748-1.15.137-.353.3-.882.344-1.857.048-1.055.058-1.37.058-4.041v-.08c0-2.597-.01-2.917-.058-3.96-.045-.976-.207-1.505-.344-1.858a3.097 3.097 0 00-.748-1.15 3.098 3.098 0 00-1.15-.748c-.353-.137-.882-.3-1.857-.344-1.023-.047-1.351-.058-3.807-.058zM12 6.865a5.135 5.135 0 110 10.27 5.135 5.135 0 010-10.27zm0 1.802a3.333 3.333 0 100 6.666 3.333 3.333 0 000-6.666zm5.338-3.205a1.2 1.2 0 110 2.4 1.2 1.2 0 010-2.4z"
                clipRule="evenodd"
              />
            </svg>
          </a>
          <a href="/" className="ml-6 text-gray-400 hover:text-gray-500">
            <span className="sr-only">Twitter</span>
            <svg className="h-6 w-6" fill="currentColor" viewBox="0 0 24 24">
              <path d="M8.29 20.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0022 5.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.072 4.072 0 012.8 9.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 012 18.407a11.616 11.616 0 006.29 1.84" />
            </svg>
          </a>
          <a href="/" className="ml-6 text-gray-400 hover:text-gray-500">
            <span className="sr-only">GitHub</span>
            <svg className="h-6 w-6" fill="currentColor" viewBox="0 0 24 24">
              <path
                fillRule="evenodd"
                d="M12 2C6.477 2 2 6.484 2 12.017c0 4.425 2.865 8.18 6.839 9.504.5.092.682-.217.682-.483 0-.237-.008-.868-.013-1.703-2.782.605-3.369-1.343-3.369-1.343-.454-1.158-1.11-1.466-1.11-1.466-.908-.62.069-.608.069-.608 1.003.07 1.531 1.032 1.531 1.032.892 1.53 2.341 1.088 2.91.832.092-.647.35-1.088.636-1.338-2.22-.253-4.555-1.113-4.555-4.951 0-1.093.39-1.988 1.029-2.688-.103-.253-.446-1.272.098-2.65 0 0 .84-.27 2.75 1.026A9.564 9.564 0 0112 6.844c.85.004 1.705.115 2.504.337 1.909-1.296 2.747-1.027 2.747-1.027.546 1.379.202 2.398.1 2.651.64.7 1.028 1.595 1.028 2.688 0 3.848-2.339 4.695-4.566 4.943.359.309.678.92.678 1.855 0 1.338-.012 2.419-.012 2.747 0 .268.18.58.688.482A10.019 10.019 0 0022 12.017C22 6.484 17.522 2 12 2z"
                clipRule="evenodd"
              />
            </svg>
          </a>
        </div>
        <div className="mt-8 md:mt-0 md:order-1">
          <p className="text-center text-base leading-6 text-gray-400">
            2020 Cuddy. All rights reserved.
          </p>
        </div>
      </div>
    </section>
  )
}

function VideoPlayer() {
  return (
    <div className="mt-10 flex justify-center max-w-screen-xl px-4 sm:mt-12 sm:px-6 md:mt-16 lg:mt-20 xl:mt-28">
      <div className="w-full bg-yellow-400">test</div>
    </div>
  )
}

function Newsletter({ ctaClicked }) {
  const [submitted, setSubmitted] = useState(false)
  return (
    <section className="bg-lightgray md:bg-white pt-16" id="newsletter">
      <div className="bg-blue-600">
        <div className="max-w-screen-xl mx-auto py-12 px-4 sm:px-6 lg:py-24 lg:px-8 lg:flex lg:items-center">
          <div className="lg:w-0 lg:flex-1">
            <h2 className="text-3xl leading-9 font-bold tracking-tight text-white sm:text-4xl sm:leading-10">
              Du willst wissen wann du Cuddy verwenden kannst?
              <span role="img" aria-label="rocket" className="ml-1">
                🤓
              </span>
            </h2>

            <p className="mt-3 max-w-3xl text-lg leading-6 text-blue-300">
              Melde dich für unseren Newsletter an! Wir schicken dir eine Email,
              sobald die App zur Verfügung steht!
            </p>

            {submitted && (
              <p className="max-w-3xl leading-6 text-green-400">
                Your email has been added successfully!
              </p>
            )}
          </div>
          <div className="mt-8 lg:mt-0 lg:ml-8">
            <form
              className="sm:flex"
              action="https://formspree.io/xknzkdgk"
              method="POST"
              onSubmit={() => setSubmitted(true)}
            >
              <input
                aria-label="Email address"
                type="email"
                name="email"
                required
                id="bd-email"
                className="appearance-none w-full px-5 py-3 border border-transparent text-base leading-6 rounded-md text-gray-900 bg-white placeholder-gray-500 focus:outline-none focus:placeholder-gray-400 transition duration-150 ease-in-out"
                placeholder="Deine Email"
              />
              <input
                type="hidden"
                name="_next"
                value="https://cuddy.app/thank-you"
              />
              <div className="mt-3 rounded-md shadow sm:mt-0 sm:ml-3 sm:flex-shrink-0">
                <button
                  type="submit"
                  className="w-full flex items-center justify-center px-5 py-3 border border-transparent text-base leading-6 font-medium rounded-md text-white bg-blue-500 hover:bg-blue-400 focus:outline-none focus:bg-blue-400 transition duration-150 ease-in-out"
                >
                  Anmelden
                </button>
              </div>
            </form>
            <p className="mt-3 text-sm leading-5 text-blue-300">
              Wir nehmen Datenschutz sehr ernst. Lies unsere
              <a
                href="https://www.iubenda.com/privacy-policy/21951540"
                className="text-white font-medium underline ml-1"
              >
                Datenschutz-Bestimmungen.
              </a>
            </p>
          </div>
        </div>
      </div>
    </section>
  )
}

function MobileNav({ setMobileNavOpen, scrollToId }) {
  return (
    <div className="absolute top-0 inset-x-0 p-2 md:hidden">
      <div
        className="rounded-lg shadow-md transition transform origin-top-right"
        x-show="open"
      >
        <div className="rounded-lg bg-white shadow-xs overflow-hidden">
          <div className="px-5 pt-4 flex items-center justify-between">
            <div>
              <img className="h-8 w-auto" src="/cuddy.svg" alt="" />
            </div>
            <div className="-mr-2">
              <button
                onClick={(): void => setMobileNavOpen(false)}
                type="button"
                className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 focus:text-gray-500 transition duration-150 ease-in-out"
              >
                <svg
                  className="h-6 w-6"
                  stroke="currentColor"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </button>
            </div>
          </div>
          <div className="px-2 pt-2 pb-3">
            <a
              href="#features"
              className="block px-3 py-2 rounded-md text-base font-medium text-gray-700 hover:text-gray-900 hover:bg-gray-50 focus:outline-none focus:text-gray-900 focus:bg-gray-50 transition duration-150 ease-in-out"
            >
              Features
            </a>
            <a
              href="#newsletter"
              className="mt-1 block px-3 py-2 rounded-md text-base font-medium text-gray-700 hover:text-gray-900 hover:bg-gray-50 focus:outline-none focus:text-gray-900 focus:bg-gray-50 transition duration-150 ease-in-out"
            >
              Formular ausfüllen
            </a>
          </div>
          <div>
            <a
              href="#newsletter"
              className="block w-full px-5 py-3 text-center font-medium text-blue-600 bg-gray-50 hover:bg-gray-100 hover:text-blue-700 focus:outline-none focus:bg-gray-100 focus:text-blue-700 transition duration-150 ease-in-out"
            >
              Lernpartner finden
            </a>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Home
